import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxSonnerToaster, toast } from "ngx-sonner";
import { NoDataComponent } from "../shared/no-data/no-data.component";
// import { NotificationService } from "src/app/service/notification.service";

interface Cycle {
  value: string;
  viewValue: string;
}
interface Urgency {
  value: string;
  viewValue: string;
}
interface Type {
  value: string;
  viewValue: string;
}

interface AllInventoryReq {
  value: string;
  viewValue: string;
}
interface PendingInventoryReq {
  value: string;
  viewValue: string;
}
interface ApprovedInventoryReq {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-inventory-request",
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    NgClass,
    FormsModule,
    MatDatepickerModule,
    NgxSonnerToaster,
    NoDataComponent
],
  templateUrl: "./inventory-request.component.html",
  styleUrls: ["./inventory-request.component.css", "../styles/inventory.css"],
})
export class InventoryRequestComponent {
showInventoryRequest: boolean = true;
showPendingRequest: boolean = false;
showApprovedRequest: boolean = false;

  showForwardButton = false;
  inventoryRequestForm: FormGroup
  constructor(private fb: FormBuilder, private inventoryService: InventoryService) {

    this.inventoryRequestForm = this.fb.group({
      projectName: ['', Validators.required],
      startDate: ['', Validators.required],
      requestFor: this.fb.group({
        number: ['', Validators.required],
        cycle: ['', Validators.required],
      }),
      urgency: ['', Validators.required],
      approverName: ['', Validators.required],
      projectLocation: ['', Validators.required],
      requestType: ['', Validators.required],
      selectedInventory: [null, Validators.required]
    })
  }

  userDepartment: string | null = null;
  ngOnInit() {
    this.getAllUsers();
    this.getInventoryData();
    this.getInventoryRequest()
    this.userDepartment = localStorage.getItem('department');
    this.showForwardButton = this.userDepartment === 'HR';
  }

  allInventoryReq: AllInventoryReq[] = [
    { value: 'Inventory Request', viewValue: 'Inventory Request' }
  ]

  pendingInventoryReq: PendingInventoryReq[] = [
    { value: 'Inventory Request', viewValue: 'Pending Request' }

  ]
  approvedInventoryReq: ApprovedInventoryReq[] = [
    { value: 'Inventory Request', viewValue: 'Approved Request' }
  ]


  // Reset Form
  openInventoryRequest() {
    this.inventoryRequestForm.reset();
    this.formSubmitted = false;
  }

  // Select Fields
  cycles: Cycle[] = [
    { value: 'days', viewValue: 'Days' },
    { value: 'weeks', viewValue: 'Weeks' },
    { value: 'months', viewValue: 'Months' },
    { value: 'years', viewValue: 'Years' },
  ];

  urgencies: Urgency[] = [
    { value: 'High', viewValue: 'High' },
    { value: 'Medium', viewValue: 'Medium' },
    { value: 'Low', viewValue: 'Low' },
  ];

  types: Type[] = [
    { value: 'For-Demo', viewValue: 'For Demo' },
    { value: 'Personal-Use', viewValue: 'Personal Use' },
  ]

  //Request For - Dropdown
  numbers: number[] = Array.from({ length: 100 }, (_, i) => i + 1);


  //Listing of Users in Dropdown
  users: { value: string, viewValue: string, id: string }[] = [];
  getAllUsers(): void {
    this.inventoryService.getInvUsers().subscribe(res => {
      let data = res["data"];
      this.users = data.map((user: any) => ({
        value: user.userName,
        viewValue: user.userName,
        id: user._id
      }));
      console.log("users", this.users);

    });
  }


  //On Select Asset field
  selectedInventoryControl = new FormControl();
  onInventoryChange(event: MatAutocompleteSelectedEvent) {
    const selectedInventory = event.option.value;
    this.selectedInventoryControl.setValue(selectedInventory);

    this.inventoryRequestForm.patchValue({
      selectedInventory: selectedInventory
    });

    const assetId = selectedInventory ? selectedInventory.assetId : null;
    console.log('Selected Asset ID:', assetId);

    this.inventoryRequestForm.patchValue({
      assetId: assetId
    });
  }

  displayFn(inventory: any): string {
    return inventory && inventory.viewValue ? inventory.viewValue : '';
  }

  //Listing of All created Inventories in Dropdown
  inventory: any[] = [];
  getInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 500,
    };

    this.inventoryService.getAllInventory(field).subscribe(
      (response: any) => {
        this.inventory = response.data.records.reverse().map((item: any) => ({

          value: item.name,
          viewValue: item.name,
          images: item.images || [],
          assetId: item.assetId || null
        }));
      },

      (error) => {
        console.error("Error", error);
      }
    );
  }
  searchedInventory = []
  totalSearchedInv: any;
  searchQuery: string;
  onSearchInventory() {
    const field: any = {
      pageNum: 1,
      limit: 100,
      searchQuery: this.searchQuery,
    };

    if (this.searchQuery === "") {
      this.clearSearch();
    } else {
      this.inventoryService.getSearchedInventory(field).subscribe(
        (response: any) => {
          console.log(response);
          this.searchedInventory = response.data.records.map((item: any) => ({
            value: item.name,
            viewValue: item.name,
            images: item.images || [],
            assetId: item.assetId || null,
          }));
          console.log("Searched", this.searchedInventory);
          this.inventory = this.searchedInventory;
          this.totalSearchedInv = response.data.totalCounts;
        },
        (error) => {
          console.error("Error", error);
        }
      );
    }
  }

  clearSearch() {
    this.searchQuery = "";
    this.getInventoryData();
  }

  //DATE Format
  formatStartDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }


  // Inventory Request Form Submit
  formSubmitted = false;
  onSubmitForm() {
    console.log(this.inventoryRequestForm.value);

    const inventoryValues = {
      ...this.inventoryRequestForm.value
    };

    const startDate = new Date(inventoryValues.startDate);
    const formattedStartDate = this.formatStartDate(startDate);

    const approver = this.users.find(user => user.value === inventoryValues.approverName);
    console.log('approver:', approver);
    const approverId = approver ? approver.id : "null";
    console.log({ approverId });

    const getInventoryFormValue: any = {
      projectName: String(inventoryValues.projectName),
      startDate: {
        day: startDate.getDate(),
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear()
      },
      requestFor: {
        number: inventoryValues.requestFor.number || '',
        cycle: inventoryValues.requestFor.cycle || ''
      },
      projectLocation: inventoryValues.projectLocation,
      requestType: inventoryValues.requestType,
      urgency: inventoryValues.urgency,
      approverName: String(inventoryValues.approverName),
      approverId: approverId,
      assetId: inventoryValues.selectedInventory.assetId
    };

    this.inventoryService.addInventoryRequest(getInventoryFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.formSubmitted = true;
      this.getInventoryRequest();
    }, error => {
      console.log('Error', error);
    });
  }
  pendingReq = [];
  totalPending: any;

  onFilterPendingRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    this.currentRequestType = requestType;
    const getPendingReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getPendingRequest(getPendingReqValue,field).subscribe(response => {
      console.log("Pending Request");
      this.pendingReq = response['data'].records;
      this.totalPending = response['data'].totalCounts
      this.showInventoryRequest = false;
      this.showApprovedRequest = false;
      this.showPendingRequest = true;
    })
  }

  approveInv = [];
  totalApproved: any;
  currentRequestType: string = '';
  onFilterApprovedRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    this.currentRequestType = requestType;
    const getApprovedReqValue: any = {
      requestType: requestType,
    }
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getApprovedRequest(getApprovedReqValue,field).subscribe(response => {
      console.log("Approved Request", response);
      this.approveInv = response['data'].records;
      this.totalApproved = response['data'].totalCounts;
      this.showInventoryRequest = false;
      this.showApprovedRequest = true;
      this.showPendingRequest = false;



    })
  }


  // Listing of All Inventory Request
  inventoriesReq = [];
  totalInventoryRequest: any;
  getInventoryRequest() {
    console.log("loggged")
    const field: any = {
      pageNum: 1,
      limit: 20,
    };
    this.inventoryService.getAllInventoryRequest(field).subscribe(response => {
      console.log("Response", response);
      this.inventoriesReq = response['data'].records
      this.totalInventoryRequest = response['data'].totalCounts
      console.log(this.inventoriesReq)
      this.showInventoryRequest = true;
      this.showApprovedRequest = false;
      this.showPendingRequest = false;
    })
  }

  acceptRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Inventory Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      // this.notify.showSuccess()
      // toast('Event has been created');
      this.getInventoryRequest();
    })
  }

  declineRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Inventory Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getInventoryRequest();
    })
  }

  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if(this.showInventoryRequest){
    return  this.inventoriesReq.slice(startIndex, endIndex);
    }
    if(this.showPendingRequest){
        return  this.pendingReq.slice(startIndex, endIndex);
      }
    if(this.showApprovedRequest){
      return this.approveInv.slice(startIndex, endIndex);
    }
  }

 

  //Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
    };
    if(this.showInventoryRequest){
      this.inventoryService.getAllInventoryRequest(option).subscribe(res => {
        this.inventoriesReq = res['data'].records
        this.totalInventoryRequest = res['data'].totalCounts
      }
    );
  }
  if(this.showApprovedRequest){
    this.inventoryService.getApprovedRequest(this.currentRequestType, option).subscribe(res => {
      this.approveInv = res['data'].records;
      this.totalApproved = res['data'].totalCounts;
    })
  }
  if(this.showPendingRequest){
    this.inventoryService.getPendingRequest(this.currentRequestType, option).subscribe(res => {
      this.pendingReq = res['data'].records;
      this.totalPending = res['data'].totalCounts
    })
  }
}

  get totalPages(): number {
    if(this.showInventoryRequest){
    return Math.ceil(this.totalInventoryRequest / this.itemsPerPage);
  }
  if(this.showApprovedRequest){
    return Math.ceil(this.totalApproved / this.itemsPerPage)
  }
  if(this.showPendingRequest){
    return Math.ceil(this.totalPending / this.itemsPerPage)
  }
}

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }
}
