<app-header></app-header>
<div class="row crm-main-container">
  <div class="side-nav-banner" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="side-menu">
      <ul>
        <li [ngClass]="{'active': activeMenu === 'dashboard'}"
          (click)="navigateTo('dashboard', '/inventory/dashboard')">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M2 2h9v7H2zm2 2v3h5V4zm9-2h9v11h-9zm2 2v7h5V4zM2 11h9v11H2zm2 2v7h5v-7zm9 2h9v7h-9zm2 2v3h5v-3z" />
          </svg>
          <span>Dashboard</span>
        </li>
        <li [ngClass]="{'active': activeMenu === 'inventories'}"
          (click)="navigateTo('inventories', '/inventory/inventories')">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="m15.5 17.125l4.95-4.95q.275-.275.7-.275t.7.275t.275.7t-.275.7l-5.65 5.65q-.3.3-.7.3t-.7-.3l-2.85-2.85q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h4.175q.275-.875 1.075-1.437T12 1q1 0 1.788.563T14.85 3H19q.825 0 1.413.588T21 5v4q0 .425-.288.713T20 10t-.712-.288T19 9V5h-2v2q0 .425-.288.713T16 8H8q-.425 0-.712-.288T7 7V5H5v14h5q.425 0 .713.288T11 20t-.288.713T10 21zm7-16q.425 0 .713-.288T13 4t-.288-.712T12 3t-.712.288T11 4t.288.713T12 5" />
          </svg>
          <span>Inventories</span>
        </li>
        <li [ngClass]="{'active': activeMenu === 'workstation'}"
          (click)="navigateTo('workstation', '/inventory/workstation')">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 48 48">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
              d="M25 6H9a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-8M24 34v8m6-30h12m-6-6v12M14 42h20" />
          </svg>
          <span>Workstation</span>
        </li>
        <div>
          <li [ngClass]="{'active': !isMenuExpanded && activeMenu === 'request'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M20 8h-3V6c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v10h20V10c0-1.1-.9-2-2-2M9 6h6v2H9zm11 12H4v-3h2v1h2v-1h8v1h2v-1h2zm-2-5v-1h-2v1H8v-1H6v1H4v-3h16v3z" />
            </svg>
            <span style="border-bottom: 1px solid #e7e7e7;font-weight: 600;">Request</span>
          </li>
          <div style="margin-left: 20px;">
            <li [ngClass]="{'active': isMenuExpanded && activeMenu === 'service-request'}"
              (click)="navigateTo('service-request', '/inventory/service-request')">
              <span>Service Requests</span>

            </li>
            <li [ngClass]="{'active': isMenuExpanded && activeMenu === 'inventory-request'}"
              (click)="navigateTo('inventory-request', '/inventory/inventory-request')">
              <span>Inventory Requests</span>
            </li>
            <li [ngClass]="{'active': isMenuExpanded && activeMenu === 'newInventory-request'}"
              (click)="navigateTo('newInventory-request', '/inventory/new-inventory-request')">
              <span>New Request</span>
            </li>
           @if (showAdminRequest) {
            <li [ngClass]="{'active': isMenuExpanded && activeMenu === 'admin-request'}"
              (click)="navigateTo('admin-request', '/inventory/admin-request')">
              <span>Admin Requests</span>
            </li>
          }
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>