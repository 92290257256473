import { Component, Input, input } from '@angular/core';
import { FormBuilder, FormGroup, Validators , ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { CrmService } from 'src/app/service/crm.service';


@Component({
  selector: 'app-forward-req',
  standalone: true,
  imports: [MatInputModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './forward-req.component.html',
  styleUrls: ["../../styles/inventory.css"]
})
export class ForwardReqComponent {
  @Input({required: true}) selectedInventory: any;
  forwardRequestForm: FormGroup;


  constructor(private fb: FormBuilder,private inventoryService: InventoryService, private crmService: CrmService){
    this.forwardRequestForm = this.fb.group({
      maximumCost: ['', Validators.required],
      comment: ['', Validators.required],
      link1: ['', Validators.required],
      link2: ['', Validators.required],
      link3: ['', Validators.required],
      selectedAdmin: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.getAdminUsersList();
    console.log({selectedInventory: this.selectedInventory})
  }


  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "ADMIN";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showForwardSuccess = false;
   onSubmitForwardReq(newInventory: any) {
    console.log({ newInventory })
    console.log('REQUEST ID', newInventory.requestId)
    console.log(this.forwardRequestForm.value)
    const forwardReqValues = {
      ...this.forwardRequestForm.value
    };

    const linksArray = [
      forwardReqValues.link1,
      forwardReqValues.link2,
      forwardReqValues.link3
    ].filter(link => link);


    const getForwardRequestFormValue: any = {
      maximumCost: String(forwardReqValues.maximumCost),
      comment: String(forwardReqValues.comment),
      links: linksArray,
      requestId: newInventory.requestId,
      adminId: forwardReqValues.selectedAdmin
    };
    console.log(newInventory?.requestId, 'REQUEST ID')
    this.inventoryService.forwardRequest(getForwardRequestFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.showForwardSuccess = true
    });
  }
}
