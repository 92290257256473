<div class="containerBanner">
    <div class="menu-container">

        <h1>Admin Requests<span>({{ this.totalForwarded}})</span></h1>
        <div class="menu-content d-flex align-items-center justify-content-between">
            <div class="search-menu">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="search-icon">
                    <g opacity="0.4">
                        <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)"
                            stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
                        <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802"
                            stroke-linecap="round" />
                    </g>
                </svg>
                <input type="search" placeholder="Search requests.." />
            </div>
            <div class="btn-container d-flex align-items-center justify-content-between">


                <select id="admin" class="form-control" formControlName="admin" style="margin-left: 15px">
                    <option value="admin" selected>All Admins</option>
                </select>

                <mat-form-field style="width:185px!important;height: 45px!important;">

                    <mat-select placeholder="select">
                        @for (request of requests; track $index) {
                        <mat-option [value]="request.value" (click)="onRequestSelect(request.value)">
                            {{request.viewValue}}
                        </mat-option>
                        }
                    </mat-select>

                </mat-form-field>

            </div>
        </div>
    </div>

    <!-- service Request Table -->
    <div class="checked-out-inv" >
        <table style="overflow-x: auto; max-height: 510px;height: 510px;">
            <thead>
                <tr>
                    <th scope="col">ASSET NAME</th>
                    <th scope="col">REQUESTED BY</th>
                    <th scope="col">STATUS</th>
                    @if(showNewInv){
                    <th scope="col">LINKS</th>
                    }
                    @if(showService){
                    <th scope="col">SERVICE TYPE</th>
                    <th scope="col">DAMAGE</th>
                    <th scope="col">OTHER DETAILS</th>
                    }
                    <th scope="col">REQUESTED ON</th>
                    <th scope="col">NEED BY</th>
                    <th scope="col">URGENCY</th>
                    <th scope="col">FORWARDED ON</th>
                    <th scope="col">FORWARDED BY</th>
                    <th scope="col">MAXIMUM COST(₹)</th>
                    <th scope="col">ACTIONS</th>

                </tr>
            </thead>
            <tbody>
                @for (adminReq of adminRequest; track $index) {
                <tr>
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="request-img">
                                <img src="../../../assets/inventories/computer-system.jpg" alt="" width="60px"
                                    height="60px" />
                            </div>
                            <p>{{adminReq.assetName}}</p>
                        </div>
                    </td>
                    <td>
                        <p>{{adminReq.requestedBy}}</p>
                    </td>
                    <td>
                        <p class="status">{{adminReq.status}}</p>
                    </td>
                    @if(showNewInv){
                    <td>
                        <p class="link"><a [href]="adminReq.links[0]">{{adminReq.links[0]!== "null" ?
                                adminReq.links[0] : '-' }}</a></p>
                        <p class="link"><a [href]="adminReq.links[1]">{{adminReq.links[1]!== "null" ?
                                adminReq.links[1] : ''}}</a></p>
                        <p class="link"><a [href]="adminReq.links[2]">{{adminReq.links[2]!== "null" ?
                                adminReq.links[2] : ''}}</a></p>
                    </td>
                    }
                    @if(showService){
                    <td>
                        <p>{{adminReq.serviceType}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.damage}}</p>
                    </td>
                    <td>
                        <p class="other-details">
                            {{adminReq.details}}
                        </p>
                    </td>
                    }
                    <td>
                        <p>{{adminReq.requestedOn}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.needBy}}</p>
                    </td>

                    <td>
                        <p [ngClass]="{
                            'text-red':adminReq.urgency === 'High',
                            'text-orange':adminReq.urgency === 'Medium',
                            'text-blue':adminReq.urgency === 'Low'
                          }">{{adminReq.urgency}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.forwardedOn}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.forwardedBy}}</p>
                    </td>

                    <td>
                        <p>₹{{adminReq.maximumCost}}</p>
                    </td>


                    <td>
                        <button class="accept" data-toggle="modal" data-target="#acceptRequestConfirm"
                            style="width: 140px;margin-right: 15px;">
                            Accept
                        </button>
                        <button class="on-hold" style="margin-right: 10px;" data-toggle="modal" data-target="#onHoldConfirm">Put on Hold</button>
                        <button class="decline" style="width: 140px;" data-toggle="modal" data-target="#declineRequestConfirm">Decline</button>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>

    <!-- Pagination -->
   <nav aria-label="Page navigation example" style="position: absolute;left: 50%;bottom: 5%;">
        <ul class="pagination" style="margin-top: 10px;">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a class="page-link" href="javascript:void(0)" aria-label="Previous"
              (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
              </svg>
            </a>
          </li>
          @for(page of visiblePages; track page){
          <li class="page-item" [ngClass]="{
              active: currentPage === page,
              hidden: !isPageVisible(page)
            }">
            <a class="page-link" href="javascript:void(0)" (click)="pageChanged(page)">{{ page }}</a>
          </li>
          }
      
          <li class="page-item" [ngClass]="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="
                currentPage !== totalPages ? pageChanged(currentPage + 1) : null
              ">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" />
              </svg>
            </a>
          </li>
        </ul>
      </nav>  

    <!-- Accept Request Modal -->
    @for(adminreq of adminRequest; track $index){
    <div class="modal fade" id="acceptRequestConfirm" tabindex="-1" role="dialog"
        aria-labelledby="acceptRequestConfirmTitle" aria-hidden="true" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered accept-width-content" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
                      <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                          stroke-linecap="round" />
                        <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                          stroke-linecap="round" />
                      </svg>
                    </button>
                  </div>
                <div class="modal-body" style="padding: 90px 20px 20px 20px;">
                    <h1 class="text-center" style="font-size: 28px;font-weight: 600;">Accept Request?</h1>
                    <p class="text-center" style="font-size: 16px;font-weight: 600;opacity:0.5;">Are you sure you
                        want to approve
                        the request?</p>
                    <button type="button" class="confirm" (click)="acceptRequest(adminreq.requestId)">Confirm</button>
               @if(showAcceptSuccess){
                    <div>
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.0024 4.17479C46.2915 4.17479 54.2979 7.18819 60.53 12.6536C66.7621 18.1189 70.7947 25.6635 71.8767 33.8817C72.9586 42.0998 71.016 50.4311 66.4109 57.3232C61.8057 64.2153 54.852 69.1983 46.8454 71.3436C38.8388 73.489 30.3252 72.6505 22.891 68.9843C15.4567 65.3182 9.60879 59.0744 6.4367 51.4163C3.2646 43.7582 2.9847 35.208 5.64913 27.3588C8.31357 19.5097 13.7406 12.8968 20.9192 8.75224" stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M25.1914 38.3414L33.733 46.8831L50.8163 29.7998" stroke="#24BA61" stroke-width="5.33852" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        <h3 style="font-size: 24px;font-weight: 700;color: #030303;">Request Approved</h3>
                        <p class="text-center" style="font-size: 16px;font-weight: 600;opacity:0.5;">Request has been approved, admin and the user will be notified about this</p>                            
                    </div>
                }
                </div>
            
            </div>
        
        </div>
    </div>
    }

<!-- On Hold Modal -->
   @for(adminreq of adminRequest; track $index){
      <div class="modal fade" id="onHoldConfirm" tabindex="-1" role="dialog" aria-labelledby="onHoldConfirmTitle"
       aria-hidden="true">
 <div class="modal-dialog modal-dialog-centered accept-width-content" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
        <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
          <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
        </svg>
      </button>
    </div>
    <div class="modal-body" style="padding: 50px 20px 50px 20px;">
        <h1 class="text-center" style="font-size: 28px;font-weight: 600;">Put On Hold Till</h1>
        <form  style="margin: 45px 0;display: flex;justify-content: center;" [formGroup]="onHoldForm">
            <input type="date" id="onHoldTillDate" class="form-control" style="width:340px;padding: 15px;height: 42px;"
            formControlName="onHoldTillDate" />  
        </form>     
        <button type="button" class="onhold-confirm" (click)="onHoldConfirm(adminreq.requestId)">Confirm</button>
    </div>

  </div>
</div>
      </div> 
    }

      <!-- Decline Request Modal  -->
      @for(adminreq of adminRequest; track $index){
      <div class="modal fade" id="declineRequestConfirm" tabindex="-1" role="dialog"
      aria-labelledby="declineRequestConfirmTitle" aria-hidden="true" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered accept-width-content" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
                  <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                      stroke-linecap="round" />
                    <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                      stroke-linecap="round" />
                  </svg>
                </button>
              </div>
             
              <div class="modal-body" style="padding: 90px 20px 20px 20px;">
                <div class="reject-container">
                    <h6 style="padding-bottom:6px; text-align: center; font-weight: 600;font-size: 24px;">Reject Request</h6>
                    <p>Are you sure you want to reject the request?</p>
                    <div style="padding: 0 30px;">
                        <form [formGroup]="declineReasonForm">
                            <div class="d-flex flex-column justify-content-center" style="margin: 0 auto; width: 650px">
                              <label for="rejectionReason">Comment</label>
                              <textarea id="rejectionReason" name="contact" rows="3" formControlName="rejectionReason" required style="width: 600px;">
                              </textarea>
                            </div>
                            <button class="btn btn-decline d-flex justify-content-center align-items-center" style="margin: 30px auto;padding: 15px 60px;" (click)="onDeclineRequest(adminreq.requestId)">
                              Confirm
                            </button>
                          </form>
                    </div>
                    @if(showRejectConfirm){
                    <div class="d-flex justify-content-center align-items-center">
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.0024 4.17479C46.2915 4.17479 54.2979 7.18819 60.53 12.6536C66.7621 18.1189 70.7947 25.6635 71.8767 33.8817C72.9586 42.0998 71.016 50.4311 66.4109 57.3232C61.8057 64.2153 54.852 69.1983 46.8454 71.3436C38.8388 73.489 30.3252 72.6505 22.891 68.9843C15.4567 65.3182 9.60879 59.0744 6.4367 51.4163C3.2646 43.7582 2.9847 35.208 5.64913 27.3588C8.31357 19.5097 13.7406 12.8968 20.9192 8.75224" stroke="#F74A4A" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.8711 46.8831L46.9544 29.7998" stroke="#F74A4A" stroke-width="5.33852" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.8699 29.7998L46.9531 46.8831" stroke="#F74A4A" stroke-width="5.33852" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h3>Request Rejected</h3>
                            <p>Request has been rejected, admin and the user will be notified about this</p>                        
                    </div>
                }
                  </div>
              </div>
          
          </div>
      </div>
  </div>
}
 </div>