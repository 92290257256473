import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from "@angular/forms";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { MediaService } from "src/app/service/media.service";
import * as JSZip from "jszip";
import { OnlineUsersService } from "src/app/service/online-users.service";
import { saveAs } from "file-saver";

import {
  alertModal,
  alertModalWithoutConfirm,
  alertModalDelete,
} from "src/app/component/helpers/alert";

import { HttpEventType } from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UserService } from "src/app/service/user.service";
import {
  SlickCarouselComponent,
  SlickCarouselModule,
} from "ngx-slick-carousel";
import { HttpClient } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { uploadAction } from "src/app/store/counter.actions";
import { NgClass, NgStyle } from "@angular/common";

declare var MediaRecorder: any;
declare let Swiper: any;

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    SlickCarouselModule,
    ReactiveFormsModule,
    NgStyle,
    FormsModule,
  ],
})
export class UploadComponent implements OnInit {
  constructor(
    private mediaService: MediaService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private UserService: UserService,
    private globalSocketService: OnlineUsersService,
    private http: HttpClient,
    private store: Store<{ uploadInfo }>
  ) {
    this.route.url.subscribe((segments) => {
      this.currentRoute = segments.join("/").split("/")[0];
      this.editId = segments.join("/").split("/")[1];
    });
    console.log(this.currentRoute);
    if (this.currentRoute == "edit") {
      this.edit = true;

      this.editData();
    }
  }
  @ViewChild("staticBackdrop") myModal: ElementRef;
  @ViewChild("slickModal") slickModal: SlickCarouselComponent | undefined;
  @ViewChild("previewvideo", { static: false }) previewVideo: any;
  // @ViewChild('preview-pdf') previewPdf: any;
  // @ViewChild('pdf') pdf: ElementRef<HTMLObjectElement>;
  @ViewChild("pdf", { static: false }) pdf: ElementRef;
  @ViewChild("uploadfile") uploadFile: any;
  @ViewChild("uploadname") uploadName: any;
  @ViewChild("previmg") prevImg: any;
  @ViewChild("excel-preview") excelPreview: any;
  @ViewChild("excel") excel: any;
  // @ViewChild('myLoader') myLoader: any;
  @ViewChild("loadingBar", { static: false }) loadingBar: any;
  @ViewChild("prog") prog: any;
  @ViewChild("progressBar") progressBar: any;
  @ViewChild("previewImage") previewImage: any;
  @ViewChild("cameraButton") cameraButton: any;
  @ViewChild("screenCaptureButton") screenCaptureButton: any;
  uploadInProgress: boolean = false;

  currentRoute: string;
  blogFiles: any;
  ResponseData: any;
  messageError: any;
  showThumbnail = false;
  mediaId: string;
  id: any;
  previewVideoUrl!: SafeResourceUrl;
  pdfUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
  selectedCollectionType: string = "Public";
  selectedProjectType: string = "Company";
  selectedAgreementType: string = "NDA";
  data: string = "";
  filesUrl: string[] = [];
  playList = [];
  dragAndDrop: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    centerMode: true,
  };
  customTags = [];
  ImagefilesResponseUrl = [];
  loaderUpload: boolean = false;
  newCollection = true;
  firstUpload = true;
  blobImage: any = "";
  edit: boolean = false;
  //upload progrss variable
  progress: number = 0;
  selectedFile: File; //Normal-upload
  archiveInProgress: boolean = true;
  message: string;
  count$: Observable<number>;
  private countSubscription: Subscription | undefined;

  blobFile: any;
  editId: any;
  startPhoto: boolean = false;
  menuOpened = false;
  typeVideoTag = false;
  collectionTypeTag = "public";
  companyNameTag = "sqaure pixel";
  companyRelatedTags = [];
  collectionTags: string = "";
  collectionType: string = "Public";
  isCompanyProjectTag = "company";
  // ndaTag = "NDA";
  ndaTag = "NDA";
  existcollecttion = true;
  primaryTags = [
    this.collectionTypeTag,
    this.companyNameTag,
    this.isCompanyProjectTag,
    this.ndaTag,
  ];

  companyTagsArray = [
    {
      company: "Square pixel",
      companyTags: ["presentation", "excel", "image", "video", "pdf"],
    },
    {
      company: "Ink in caps",
      companyTags: [
        "presentation",
        "excel",
        "image",
        "video",
        "pdf",
        "word/docs",
      ],
    },
    { company: "Codepie", companyTags: ["js", "git", "html", "angular"] },
  ];

  saveUploadInfoToStore(body) {
    console.log({ sotreBody: body });
    this.store.dispatch(uploadAction({ body: body }));
  }
  @ViewChild("swiperContainer", { static: false }) swiperContainer: any;
  ngOnInit(): void {
    // this.getAllPlaylist(); //GET ALL PLAYLIST
    this.getInvitation();
    this.addToExistingCollection();
    this.connectionSocket();
  }

  thumbnailData: any;
  videoSTyle: any;
  connectionSocket() {
    this.globalSocketService.listen("ARCHIVE").subscribe((res) => {
      if (res?.operation && res?.data?._id === this.id) {
        let data = res.data;
        let extension = res.data.extension;
        this.thumbnailData = data.thumbnails;
        this.filesUrl = data.thumbnails;
        this.thumbnailSpinner = false;
        this.archiveInProgress = false;
        this.editId = res["data"]["_id"];
        this.id = res["data"]["_id"];
        document.getElementById("ImageId").innerHTML = this.id;

        if (res["data"]["highQuality"]) {
          let video: HTMLVideoElement = this.previewVideo?.nativeElement;
          this.editId = data["_id"];
          this.id = res["data"]._id;
          document.getElementById("ImageId").innerHTML = this.id;
          // console.log(res["data"]["highQuality"]);
          // if (res?.data?.highQuality) {
          //   video.src = res["data"]["highQuality"];
          // } else {
          //   console.error("High quality URL is not defined or empty");
          // }
          // video.setAttribute("src", res["data"]["highQuality"]);
          video.src = res["data"]["highQuality"];
          video.style.display = "block";
          video.style.objectFit = "contain";
        }

        if (
          extension === ".pdf" ||
          extension === ".js" ||
          extension === ".html" ||
          extension === ".git" ||
          extension === ".angular"
        ) {
          const pdf: HTMLObjectElement = this.pdf.nativeElement;
          let pdfFileUrl = this.uploadFile?.nativeElement;
          let filename = this.uploadName?.nativeElement;

          filename.innerHTML = this.SocketFileName;
          pdfFileUrl.innerHTML = "file uploaded successfully";
          let img = this.prevImg.nativeElement;

          if (extension == ".pdf") {
            img.setAttribute("src", "../../../assets/Archive_files/pdf.png");
          }
          if (extension == ".js") {
            img.setAttribute("src", "../../../assets/js.png");
          }
          if (extension == ".html") {
            img.setAttribute("src", "../../../assets/html.png");
          }
          if (extension == ".git") {
            img.setAttribute("src", "../../../assets/git.png");
          }
          if (extension == ".angular") {
            img.setAttribute("src", "../../../assets/angular.png");
          }
          if (extension == ".doc" || extension == ".docx") {
            img.setAttribute("src", "../../../assets/docx-img.png");
          }
          this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
        }
        if (extension === ".xlsx" || extension === ".xls") {
          // var excel = this.excelPreview.nativeElement;
          let pdfFileUrl = this.uploadFile.nativeElement;
          let filename = this.uploadName.nativeElement;

          // filename.innerHTML = this.SocketFileName;
          console.log(this.SocketFileName);
          pdfFileUrl.innerHTML = "file uploaded successfully";
          let img = this.prevImg.nativeElement;
          img.setAttribute("src", "../../../assets/excl-img.jpg");
          this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
          const myLoaderElement: HTMLElement = this.loadingBar.nativeElement;
          myLoaderElement.classList.add("hide");
        }
        if (extension === ".docx" || extension === ".doc") {
          // var excel = this.excelPreview.nativeElement;
          let pdfFileUrl = this.uploadFile.nativeElement;
          let filename = this.uploadName.nativeElement;

          // filename.innerHTML = this.SocketFileName;
          console.log(this.SocketFileName);
          pdfFileUrl.innerHTML = "file uploaded successfully";
          let img = this.prevImg.nativeElement;
          img.setAttribute("src", "../../../assets/docx-img.png");
          this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
          const myLoaderElement: HTMLElement = this.loadingBar.nativeElement;
          myLoaderElement.classList.add("hide");
        } else if (extension === ".pptx") {
          const excelElement: HTMLInputElement | null =
            this.excel.nativeElement;
          let pdfFileUrl = this.uploadFile.nativeElement;
          let filename = this.uploadName.nativeElement;

          filename.innerHTML = this.SocketFileName;
          pdfFileUrl.innerHTML = "file uploaded successfully";
          let img = this.prevImg.nativeElement;
          img.setAttribute("src", "../../../assets/ppt-file-img.png");
        }
        this.barWidth = "0%";
        this.uploadInProgress = false;
      }
    });
  }

  ngAfterViewInit(): void {}
  swiperInstance: any;
  private initializeSwiper(): void {
    this.swiperInstance = new Swiper(this.swiperContainer?.nativeElement, {
      // Optional parameters
      slidesPerView: 2,
      spaceBetween: 10,
      autoWidth: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

  onChangeCompanyTag(tag) {
    this.companyNameTag = tag;
    this.companyRelatedTags = [];
    this.primaryTags = [
      this.collectionTypeTag,
      this.companyNameTag,
      this.isCompanyProjectTag,
      this.ndaTag,
    ];
    this.primaryTags = this.primaryTags.concat(this.companyRelatedTags);
  }
  selectedCollection = "";
  selectedTags = "Presentation";

  addCompanyRelatedTags(tag: any) {
    this.selectedTags = tag;
    if (tag == "image" || tag == "screenshot") {
      this.typeVideoTag = false;
      this.cameraButton.nativeElement.classList.remove("hide");
      this.screenCaptureButton.nativeElement.classList.remove("hide");
    } else if (tag == "video") {
      this.typeVideoTag = true;
      this.cameraButton.nativeElement.classList.remove("hide");
      this.screenCaptureButton.nativeElement.classList.remove("hide");
    } else {
      this.cameraButton.nativeElement.classList.add("hide");
      this.screenCaptureButton.nativeElement.classList.add("hide");
    }

    if (this.companyRelatedTags.indexOf(tag) != -1) {
      return;
    } else {
      this.companyRelatedTags.push(tag);
    }
    this.primaryTags = [
      this.collectionTypeTag,
      this.companyNameTag,
      this.isCompanyProjectTag,
      this.ndaTag,
    ];
    this.primaryTags = this.primaryTags.concat(this.companyRelatedTags);
  }

  onChangeCollectionType(event) {
    this.selectedCollectionType = event.target.checked ? "Private" : "Public";
    this.existcollecttion = event.target.checked ? false : true;
    if (event.target.checked) {
      this.collectionTypeTag = "private";
    } else {
      this.collectionTypeTag = "public";
    }
    this.primaryTags = [
      this.collectionTypeTag,
      this.companyNameTag,
      this.isCompanyProjectTag,
      this.ndaTag,
    ];
    this.primaryTags = this.primaryTags.concat(this.companyRelatedTags);
  }

  onChangeCompanyProductType(event) {
    this.selectedProjectType = event.target.checked ? "Reference" : "Company";
    this.isCompanyProjectTag = this.selectedProjectType.toLocaleLowerCase();
    this.primaryTags = [
      this.collectionTypeTag,
      this.companyNameTag,
      this.isCompanyProjectTag,
      this.ndaTag,
    ];
    this.primaryTags = this.primaryTags.concat(this.companyRelatedTags);
  }

  onChangeNdaType(event) {
    this.selectedAgreementType = event.target.checked ? "Non-NDA" : "NDA";
    this.ndaTag = this.selectedAgreementType;
    this.primaryTags = [
      this.collectionTypeTag,
      this.companyNameTag,
      this.isCompanyProjectTag,
      this.ndaTag,
    ];
    this.primaryTags = this.primaryTags.concat(this.companyRelatedTags);
  }

  projectForm = new FormGroup({
    title: new FormControl("", [
      Validators.required,
      Validators.pattern("^(.{1,50})$"),
      Validators.maxLength(100),
    ]),
    client_name: new FormControl("", [
      Validators.required,
      Validators.maxLength(100),
    ]),
    description: new FormControl(""),
  });

  folderCheck: boolean = false;
  editData() {
    this.mediaService.getOneByMediaId(this.editId).subscribe(
      (res) => {
        console.log(res, "somethimnbjkh");

        this.customTags = res["data"].tags;
        const mediaId = res["data"]._id;
        this.primaryTags[0] = res["data"]["tags"][0];
        this.primaryTags[1] = res["data"]["tags"][1];
        this.primaryTags[2] = res["data"]["tags"][2];
        this.primaryTags[3] = res["data"]["tags"][3];

        const processedUrls = res["data"]["addtionalFiles"].map(
          ({ fileUrl, _id }) => {
            console.log({ fileUrl });
            if (!fileUrl.startsWith("https://")) {
              return "https://" + fileUrl;
            }
            return { fileUrl, _id, mediaId: mediaId };
          }
        );

        this.ImagefilesResponseUrl = processedUrls;
        console.log(res["data"]["highQuality"], "videoooooooooooooooooooooo");

        if (
          (res["data"]["highQuality"] && res["data"]["extension"] === ".mp4") ||
          res["data"]["extension"] === ".webm"
        ) {
          let video = document.getElementById("preview-video");
          video.setAttribute("src", res["data"]["highQuality"]);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 5000);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 10000);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 20000);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 30000);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 40000);
          setTimeout(() => {
            video.setAttribute("src", res["data"]["highQuality"]);
          }, 50000);
          video.style.display = "block";
          video.style.objectFit = "contain";
        }
        if (res["data"]["ogFileUrl"]) {
          if ([".jpg", ".jpeg", ".png"].includes(res["data"]["extension"])) {
            let image = document.getElementById("preview-image");
            image.setAttribute("src", res["data"]["ogFileUrl"]);
            image.style.setProperty("max-width", "548px", "important");
          }
          if (
            res["data"]["extension"].includes(".xls") ||
            res["data"]["extension"].includes(".xlsx")
          ) {
            let image = document.getElementById("preview-image");
            image.setAttribute(
              "src",
              "../../../assets/Archive_files/excel-icon.png"
            );
            // this.folderCheck = true;
          }
          if (res["data"]["extension"].includes(".pdf")) {
            let image = document.getElementById("preview-image");
            image.setAttribute("src", "../../../assets/Archive_files/pdf.png");
            image.style.setProperty("max-width", "548px", "important");
            // this.folderCheck = true;
          }
          if (res["data"]["extension"].includes(".pptx")) {
            let image = document.getElementById("preview-image");
            image.setAttribute(
              "src",
              "../../../assets/Archive_files/ppt-icon.png"
            );
            image.style.setProperty("max-width", "548px", "important");
            // this.folderCheck = true;
          }
          if (
            res["data"]["extension"].includes(".doc") ||
            res["data"]["extension"].includes(".docx")
          ) {
            let image = document.getElementById("preview-image");
            image.setAttribute("src", "../../../assets/docx-img.png");
            image.style.setProperty("max-width", "548px", "important");
            // this.folderCheck = true;
          }
        }
        let fileUpload = document.getElementById("file-uploadCamera");
        fileUpload.style.setProperty("display", "none", "important");
        this.projectForm.patchValue({
          title: res["data"].title,
          client_name: res["data"].clientName,
          description: res["data"].description,
        });
      },
      (err) => {
        console.log(err);
      }
    );
    this.projectForm = new FormGroup({
      title: new FormControl(""),
      client_name: new FormControl("", Validators.required),
      description: new FormControl(""),
    });
  }

  slickInit(e) {
    console.log("slick initialized");
    this.archiveInProgress = false;
    console.log(this.archiveInProgress, "");
  }

  removePrimaryTag(idx) {
    this.primaryTags.splice(idx, 1);
  }

  removeCustomTag(idx) {
    this.customTags.splice(idx, 1);
  }

  onEnterKey(elementEvent) {
    if (elementEvent.key == "Enter") {
      var val = (<HTMLInputElement>document.getElementById("input-search"))
        .value;
      this.customTags.push(val);
      (<HTMLInputElement>document.getElementById("input-search")).value = "";
    }
  }

  changeFile: boolean = false;
  fileChange() {
    this.changeFile = true;
  }
  onClickScreenCapture() {
    var serv = this.mediaService;
    var mediaDevices = navigator.mediaDevices as any;
    if (this.typeVideoTag) {
      let audioTrack, videoTrack;

      mediaDevices
        .getDisplayMedia({ video: true })
        .then(async (displayStream) => {
          [videoTrack] = displayStream.getVideoTracks();
          const audioStream = await navigator.mediaDevices
            .getUserMedia({ audio: true })
            .catch((e) => {
              throw e;
            });
          [audioTrack] = audioStream.getAudioTracks();

          displayStream.addTrack(audioTrack);

          document.getElementsByClassName("file-upload")[0].remove();
          let start = document.getElementById("btnStart");
          let stop = document.getElementById("btnStop");
          let keep = document.getElementById("btnKeep");
          let controler = document.getElementById("recorder-control");
          let vidSave: any = document.getElementById("vid2");
          let mediaRecorder = new MediaRecorder(displayStream);
          let chunks = [];

          var video: any = document.querySelector("#preview-video");
          video.style.display = "inline";
          video.setAttribute("autoplay", "true");
          video.srcObject = displayStream;

          video.onloadedmetadata = (e) => {
            video.play();
            controler.style.left = "357px";
            video.muted = true;
            controler.classList.remove("hide");
          };

          start.addEventListener("click", (ev) => {
            vidSave.classList.add("hide");
            video.style.display = "initial";
            stop.classList.remove("hide");
            mediaRecorder.start();
            controler.style.left = "290px";

            console.log(mediaRecorder.state);
          });

          stop.addEventListener("click", (ev) => {
            mediaRecorder.stop();
            console.log(mediaRecorder.state);
            keep.classList.remove("hide");
          });
          mediaRecorder.ondataavailable = function (ev) {
            chunks.push(ev.data);
          };
          mediaRecorder.onstop = (ev) => {
            video.style.display = "none";
            vidSave.classList.remove("hide");
            controler.style.bottom = "32px";
            controler.style.left = "153px";
            let blob = new Blob(chunks, { type: "video/mp4" });
            chunks = [];
            let videoURL = URL.createObjectURL(blob);
            vidSave.setAttribute("src", URL.createObjectURL(blob) + ".mp4");
            const file = new File(chunks, "demo1.mp4", { type: "video/mp4" });
            this.blobFile = file;
            let tracks = video.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
            video.srcObject = null;
          };
        })
        .catch(function (err) {
          alert("Screen capture not available");
        });
    } else {
      var video: any = document.getElementById("preview-video");
      var canvas: any = document.getElementById("pic-canvas");
      var photo = document.getElementById("pic-photo");
      var startbutton = document.getElementById("startbutton");
      var width = 1920;
      var height = video.videoHeight / (video.videoWidth / width);
      var upload = document.getElementsByClassName("file-upload")[0];
      var streaming = false;

      mediaDevices
        .getDisplayMedia({ video: true, audio: false })
        .then(function (stream) {
          video.style.display = "block";
          video.srcObject = stream;
          upload.classList.add("hide");

          startbutton.classList.remove("hide");
          video.play();

          video.addEventListener(
            "canplay",
            function (ev) {
              if (!streaming) {
                height = video.videoHeight / (video.videoWidth / width);

                let fileUpl = document.getElementById("file-uploadCamera");
                fileUpl.style.cssText = "display: none !important;";
                video.setAttribute("width", width);
                video.setAttribute("height", height);
                canvas.setAttribute("width", width);
                canvas.setAttribute("height", height);

                streaming = true;
              }
            },
            false
          );

          startbutton.addEventListener(
            "click",
            function (ev) {
              photo.classList.remove("hide");
              var context = canvas.getContext("2d");

              if (width && height) {
                canvas.width = width;
                canvas.height = height;
                context.drawImage(video, 0, 0, width, height);
                var data = canvas.toDataURL("image/png");

                canvas.toBlob(
                  (blob) => {
                    if (blob) {
                      console.log("Blob:", blob);
                      const file = new File([blob], "screenshot.jpeg", {
                        type: "image/jpeg",
                      });
                      // this.blogFile = file;
                      serv.createMedia(file).subscribe(
                        (resp) => {
                          console.log(resp, "image");
                          if (resp.type === HttpEventType.UploadProgress) {
                            const progress = Math.round(
                              (100 / (resp.total || 0)) * resp.loaded
                            );
                          } else if (resp.type === HttpEventType.Response) {
                            this.id = resp["body"]["data"]._id;

                            document.getElementById("ImageId").innerHTML =
                              this.id;

                            document
                              .getElementById("my-loader")
                              .classList.add("hide");
                          }

                          // serv.mediaID = resp["data"]._id;
                          console.log(resp, "photo");
                          document
                            .getElementById("my-loader")
                            .classList.add("hide");
                        },
                        (error) => {
                          console.error("Error uploading media:", error);
                          document
                            .getElementById("my-loader")
                            .classList.add("hide");
                        }
                      );
                    }
                  },
                  "image/jpeg",
                  0.9
                );
                photo.setAttribute("src", data);
              } else {
                var context = canvas.getContext("2d");
                context.fillStyle = "#AAA";
                context.fillRect(0, 0, canvas.width, canvas.height);

                var data = canvas.toDataURL("image/png");
                photo.setAttribute("src", data);
              }

              ev.preventDefault();
            },
            false
          );
        })
        .catch(function (err) {
          console.log("An error occurred: " + err);
        });
    }
    this.archiveInProgress = false;
  }

  canvasDisplayImage: boolean = true;
  imageDis: string = "inline";
  takePhoto() {
    document.getElementById("preview-video").style.display = "none";
    var image = document.getElementById("pic-photo");
    if (document.getElementById("pic-photo")) {
      image.style.width = "100%";
      image.style.height = "100%";
      image.style.objectFit = "cover";
    }
    this.uploadSelection = false;
    this.imageDis = this.imageDis === "inline" ? "none" : "inline";

    this.canvasDisplayImage = false;
  }
  pdfDisplay: string = "none";
  deleteUpload: boolean = false;
  pdfDis: string = "inline";
  togglePdfDisplay() {
    this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
  }
  barWidth: string = "0%";
  imageStyle: string = "none";
  videoStyle: string = "none";

  onClickUpload() {
    console.log("==========onClickUpload================");
    var serv = this.mediaService;
    this.globalSocketService;
    const input: any = document.getElementById("upload-input");
    const video: any = document.getElementById("preview-video");
    const image: any = document.getElementById("preview-image");
    const pdf: HTMLElement | null = document.getElementById("preview-pdf");
    if (pdf) {
      pdf.style.display = "none";
    }
    input.click();
    input.addEventListener("change", (event) => {
      {
        if (this.firstUpload) {
          this.uploadMedia(event);
          this.firstUpload = false;
        } else {
        }
      }
    });
  }

  onClickUploadEdit() {
    var serv = this.mediaService;
    const input: any = document.getElementById("upload-input");
    const video: any = document.getElementById("preview-video");
    const image: any = document.getElementById("preview-image");
    const pdf: HTMLElement | null = document.getElementById("preview-pdf");
    if (pdf) {
      pdf.style.display = "none";
    }
    input.click();
  }

  thumbnailSpinner: boolean = false;
  socketUploadFile: File;
  SocketFileName: any;
  additionalEditId: any;
  videoUrl: any = "";

  uploadSelection: boolean = true;
  onClickCamera() {
    var serv = this.mediaService;
    if (this.typeVideoTag) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          document.getElementsByClassName("file-upload")[0].remove();
          let controler = document.getElementById("recorder-control");
          let start = document.getElementById("btnStart");
          let stop = document.getElementById("btnStop");
          let keep = document.getElementById("btnKeep");
          let vidSave: any = document.getElementById("vid2");
          let mediaRecorder = new MediaRecorder(stream);
          let chunks = [];

          var video: any = document.querySelector("#preview-video");
          video.style.display = "inline";
          video.setAttribute("autoplay", "true");
          video.srcObject = stream;
          video.onloadedmetadata = (e) => {
            video.play();
            controler.style.left = "339px";
            controler.classList.remove("hide");
          };

          start.addEventListener("click", (ev) => {
            mediaRecorder.start();
            stop.classList.remove("hide");
            controler.style.left = "257px";
            console.log(mediaRecorder.state);
          });
          stop.addEventListener("click", (ev) => {
            mediaRecorder.stop();
            controler.style.left = "153px";
            controler.style.bottom = "33px";
            document.getElementById("vid2").style.width = "300px";
            keep.classList.remove("hide");
            console.log(mediaRecorder.state);
          });
          mediaRecorder.ondataavailable = function (ev) {
            chunks.push(ev.data);
          };
          mediaRecorder.onstop = (ev) => {
            video.style.display = "none";
            vidSave.classList.remove("hide");
            let blob = new Blob(chunks, { type: "video/mp4;" });
            chunks = [];
            let videoURL = URL.createObjectURL(blob);
            const file = new File(chunks, "demo.mp4", { type: "video/mp4" });
            vidSave.setAttribute("src", URL.createObjectURL(blob));
            this.blobFile = file;
          };
        })
        .catch(function (err) {
          alert("Camera support not available");
        });
    } else {
      var mediaDevices = navigator.mediaDevices as any;
      var video: any = document.getElementById("preview-video");
      var canvas: any = document.getElementById("pic-canvas");
      var photo = document.getElementById("pic-photo");
      var startbutton = document.getElementById("startbutton");
      var width = 320;
      var height = video.videoHeight / (video.videoWidth / width);
      var upload = document.getElementsByClassName("file-upload")[0];
      var streaming = false;
      this.startPhoto = true;
      mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(function (stream) {
          video.style.display = "block";
          video.srcObject = stream;
          upload.classList.add("hide");

          startbutton.classList.remove("hide");
          video.play();

          video.addEventListener(
            "canplay",
            function (ev) {
              if (!streaming) {
                height = video.videoHeight / (video.videoWidth / width);

                video.setAttribute("width", width);
                video.setAttribute("height", height);
                video.style.objectFit = "cover";
                video.style.height = "90%";
                let take = document.getElementById("startbutton");
                take.style.marginTop = "3px";
                canvas.setAttribute("width", width);
                canvas.setAttribute("height", height);
                streaming = true;
              }
            },
            false
          );

          (startbutton as any).addEventListener(
            "click",
            function (ev: any): any {
              photo.classList.remove("hide");
              var context = canvas.getContext("2d");
              if (width && height) {
                canvas.width = width;
                canvas.height = height;
                context.drawImage(video, 0, 0, width, height);
                var data = canvas.toDataURL("image/png");
                var imageUrl = photo.getAttribute("src");
                canvas.toBlob(
                  (blob) => {
                    if (blob) {
                      const file = new File([blob], "screenshot.jpeg", {
                        type: "image/jpeg",
                      });

                      this.blogFile = file;
                      serv.createMedia(file).subscribe(
                        (resp) => {
                          if (resp.type === HttpEventType.UploadProgress) {
                            const progress = Math.round(
                              (100 / (resp.total || 0)) * resp.loaded
                            );
                            this.barWidth = progress + "%";
                            document.getElementById("prog").innerHTML =
                              this.barWidth;
                            document.getElementById("progressBar").style.width =
                              this.barWidth;
                            this.uploadInProgress = true;
                          } else if (resp.type === HttpEventType.Response) {
                            this.barWidth = "0%";
                            this.uploadInProgress = false;

                            this.id = resp["body"]["data"]._id;
                            document.getElementById("ImageId").innerHTML =
                              this.id;

                            document
                              .getElementById("my-loader")
                              .classList.add("hide");
                          }
                          video.style.display = "none";
                          document
                            .getElementById("my-loader")
                            .classList.add("hide");
                        },
                        (error) => {
                          console.error("Error uploading media:", error);
                          document
                            .getElementById("my-loader")
                            .classList.add("hide");
                        }
                      );
                    }
                  },
                  "image/jpeg",
                  0.9
                );

                photo.setAttribute("src", data);
              } else {
                var context = canvas.getContext("2d");
                context.fillStyle = "#AAA";
                context.fillRect(0, 0, canvas.width, canvas.height);
                var data = canvas.toDataURL("image/png");
                photo.setAttribute("src", data);
              }

              ev.preventDefault();
            },
            false
          );
        })
        .catch(function (err) {
          console.log("An error occurred: " + err);
        });
    }
  }

  capitalizeFirstLetter(inputString: string): string {
    if (!inputString || inputString.length === 0) {
      return inputString;
    }

    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }
  onClickCollection() {
    console.log("id is missing");
    let id = document.getElementById("ImageId").textContent;
    if (id.length === 0) {
      return alertModalWithoutConfirm(
        "warning",
        "Please select a file first or additional images or videos"
      );
    }
    if (!this.projectForm.valid) {
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before uploading."
      );
    }
    var body: any = {
      title: this.projectForm.value["title"],
      clientName: this.projectForm.value["client_name"],
      description: this.projectForm.value["description"],
      tags: this.primaryTags.concat(this.customTags),
      selected_thumbnail: this.selectedImageUrl,
      isPublic: this.collectionTypeTag === "public" ? true : false,
      mediaId: this.mediaId,
      fileType:
        this.selectedTags === "word/docs"
          ? "Word/Docs"
          : this.capitalizeFirstLetter(this.selectedTags),
      play_list: this.playList[0],
    };
    if (this.collectionTags) {
      if (this.newCollection) {
        body.collectionData = {
          title: this.collectionTags,
          isPublic: this.collectionType === "Public" ? true : false,
        };
      } else {
        body.collectionId = this.selectedTagId;
      }
    }
    console.log({ body });

    this.mediaService.updateMedia(body).subscribe(
      (resp) => {
        console.log("updated resp", resp);
        alertModalWithoutConfirm("success", " Successfully Uploaded!");
        // this.onCreateCollection(this.title);
        setTimeout(() => {
          this.router.navigate(["home"]);
        }, 2000);
      },
      (err) => {
        console.log(err);
        const message = err.error.message || "Something went wrong!";
        alertModal("error", message);
      }
    );
  }

  onClickCollectionEdit(publicType: boolean) {
    let id = this.editId;
    var body: any = {
      title: this.projectForm.value["title"],
      clientName: this.projectForm.value["client_name"],
      description: this.projectForm.value["description"],
      tags: Array.from(new Set(this.primaryTags.concat(this.customTags))),
      selected_thumbnail: this.selectedImageUrl,
      isPublic: publicType,
      mediaId: id,
      fileType:
        this.selectedTags === "word/docs"
          ? "Word/Docs"
          : this.capitalizeFirstLetter(this.selectedTags),
      play_list: this.playList[0],
    };
    if (this.collectionTags) {
      if (this.newCollection) {
        body.collectionData = {
          title: this.collectionTags,
          isPublic: this.collectionType === "Public" ? true : false,
        };
      } else {
        body.collectionId = this.selectedTagId;
      }
    }
    this.mediaService.updateMedia(body).subscribe(
      (resp) => {
        console.log("updated resp", resp);
        this.mediaId = resp["data"]._id;
        alertModalWithoutConfirm("success", " Successfully Uploaded!");
        setTimeout(() => {
          this.router.navigate(["home"]);
        }, 2000);
      },

      (err) => {
        console.log(err);
        const message = err.error.message || "Something went wrong!";
        alertModal("error", message);
      }
    );
  }

  keepRecording() {
    document.getElementById("my-loader").classList.remove("hide");
    this.mediaService.createMedia(this.blobFile).subscribe((resp) => {
      if (resp.type === HttpEventType.UploadProgress) {
        const progress = Math.round((100 / (resp.total || 0)) * resp.loaded);
        this.barWidth = progress + "%";
        document.getElementById("prog").innerHTML = this.barWidth;
        document.getElementById("progressBar").style.width = this.barWidth;
        this.uploadInProgress = true;

        console.log("uploadProgress", resp, this.barWidth);
      } else if (resp.type === HttpEventType.Response) {
        this.barWidth = "0%";
        this.uploadInProgress = false;

        this.id = resp["body"]["data"]._id;
        document.getElementById("ImageId").innerHTML = this.id;
        document.getElementById("my-loader").classList.add("hide");
      }
      document.getElementById("my-loader").classList.add("hide");
    });
  }

  keepData() {
    document.getElementById("my-loader").classList.remove("hide");
    this.mediaService.createMedia(this.blobFile).subscribe((resp) => {
      this.mediaService.mediaID = resp["data"]["_id"];
      document.getElementById("my-loader").classList.add("hide");
    });
  }

  images: File[] = [];
  loading: boolean = false;
  onClickAddImage(event) {
    let id = document.getElementById("ImageId").textContent;
    if (id.length == 0) {
      return alertModalWithoutConfirm(
        "warning",
        "Please select a file first then additional images or videos"
      );
    }
    this.loading = true;

    for (let image of event.target.files) {
      this.images.push(image);
    }
    if (this.images.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < this.images.length; i++) {
        console.log("=====images=====: ", this.images[i]);
        formData.append("files", this.images[i]);
      }
      console.log(id, "jammmmmmmmmmmmmmmmmmmmmmmmmm");
      formData.append("mediaId", id);

      // const body = {mediaId:id,file:this.images}
      // console.log(body)

      this.mediaService.updateImage(formData).subscribe(
        (resp) => {
          const processedUrls = resp["data"]["addtionalFiles"];
          console.log({ processedUrls });
          //.map((url) => {
          //   console.log(url)
          //   if (!url.fileUrl.startsWith("https://")) {
          //     return "https://" + url;
          //   }
          //   return url.fileUrl;
          // });
          this.ImagefilesResponseUrl = processedUrls;

          this.images = [];
          this.loading = false;
        },
        (error) => {
          console.error("Error occurred during image upload", error);
          this.loading = false;
        }
      );
    }
  }

  onClickAddImageEdit(event) {
    console.log({ event });
    let id = this.editId;
    this.loading = true;
    for (let image of event.target.files) {
      this.images.push(image);
    }
    if (this.images.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < this.images.length; i++) {
        formData.append("files", this.images[i]);
      }
      formData.append("mediaId", id);
      // const body = { mediaId: id, file: { ...formData } };
      // console.log(body);
      // formData.append("public", "false");

      this.mediaService.updateImage(formData).subscribe(
        (resp) => {
          const mediaId = resp["data"]._id;
          const processedUrls = resp["data"]["addtionalFiles"].map(
            ({ fileUrl, _id }) => {
              console.log({ fileUrl });
              if (!fileUrl.startsWith("https://")) {
                return "https://" + fileUrl;
              }
              return { fileUrl, _id, mediaId: mediaId };
            }
          );
          this.ImagefilesResponseUrl = processedUrls;

          this.images = [];
          this.loading = false;
        },
        (error) => {
          console.error("Error occurred during image upload", error);
          this.loading = false;
        }
      );
    }
  }

  onClickDeleteImage(idx) {
    console.log({ idx });
    let id = document.getElementById("ImageId").textContent;
    console.log("onClickDeleteImage", idx, this.mediaService.mediaID, id);
    this.mediaService.deleteImage(id, idx.toString()).subscribe((resp) => {
      console.log("resp", resp);
      if (resp && resp["statusCode"] === 200) {
        this.ImagefilesResponseUrl = this.ImagefilesResponseUrl.filter(
          (item) => item._id !== idx
        );
        console.log(
          "Updated ImagefilesResponseUrl:",
          this.ImagefilesResponseUrl
        );
      }
    });
  }

  onClickDeleteImaEdited(idx, mediaId) {
    console.log({ idx });
    this.mediaService.deleteImage(mediaId, idx.toString()).subscribe((resp) => {
      if (resp && resp["statusCode"] === 200) {
        this.ImagefilesResponseUrl = this.ImagefilesResponseUrl.filter(
          (item) => item._id !== idx
        );
      }
    });
  }

  isImage(url: any): boolean {
    let newUrl = "";
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".jpg") ||
      newUrl.endsWith(".png") ||
      newUrl.endsWith(".gif") ||
      newUrl.endsWith(".jpeg")
    );
  }

  fileTypeImages: { [key: string]: string } = {
    doc: "../../../assets/docx-img.png",
    docx: "../../../assets/docx-img.png",
    xls: "../../../assets/excl-img.jpg",
    xlsx: "../../../assets/excl-img.jpg",
    pdf: "../../../assets/Archive_files/pdf.png",
    pptx: "../../../assets/ppt-file-img.png",
  };

  isDocument(url: any): boolean {
    let newUrl = "";
    console.log(url.split(".")[5], "urllllllll");
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".doc") ||
      newUrl.endsWith(".docx") ||
      newUrl.endsWith(".xls") ||
      newUrl.endsWith(".xlsx") ||
      newUrl.endsWith(".pdf") ||
      newUrl.endsWith(".pptx")
    );
  }

  isVideo(url: any): boolean {
    let newUrl = "";
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".mp4") ||
      newUrl.endsWith(".avi") ||
      newUrl.endsWith(".mov") ||
      newUrl.endsWith(".mkv")
    );
  }

  openWindow(data: any) {
    window.open(this.data);
  }

  // onFileDrop(event: DragEvent) {
  //   event.preventDefault();
  //   var serv = this.mediaService;
  //   const input: any = document.getElementById("upload-input");
  //   const video: any = document.getElementById("preview-video");
  //   const image: any = document.getElementById("preview-image");
  //   const pdf: HTMLElement | null = document.getElementById("preview-pdf");
  //   if (pdf) {
  //     pdf.style.display = "none";
  //   }

  //   const uploader: any = document.getElementsByClassName("file-upload")[0];
  //   const files = event.dataTransfer?.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     if (file.type == "video/mp4") {
  //       this.thumbnailSpinner = true;
  //     }
  //     this.dragAndDrop = file;
  //     serv.createMedia(file).subscribe((event) => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //         const progress = Math.round(
  //           (100 / (event.total || 0)) * event.loaded
  //         );
  //         this.barWidth = progress + "%";
  //         let fileUpload = document.getElementById("file-uploadCamera");
  //         fileUpload.style.setProperty("display", "none", "important");
  //         document.getElementById("prog").innerHTML = this.barWidth;
  //         document.getElementById("progressBar").style.width = this.barWidth;
  //         this.uploadInProgress = true;

  //         document.getElementById("my-loader").classList.remove("hide");
  //       } else if (event.type === HttpEventType.Response) {
  //         this.barWidth = "0%";
  //         this.uploadInProgress = false;

  //         this.id = event.body["data"]._id;
  //         document.getElementById("ImageId").innerHTML = this.id;
  //         const resp = event.body;
  //         this.ResponseData = resp["data"];
  //         const extension = resp["data"].extension.toLowerCase();
  //         const ogFileUrl = resp["data"].lg;
  //         this.data = resp["data"].ogFileUrl;
  //         if (extension === ".xlsx") {
  //           var excel = document.getElementById("excel-preview");
  //           let pdfFileUrl = document.getElementById("upload-file");
  //           let filename = document.getElementById("upload-name");
  //           filename.innerHTML = files[0].name;
  //           pdfFileUrl.innerHTML = "file uploaded successfully";
  //           let img = document.getElementById("prev-img");
  //           img.setAttribute("src", "../../../assets/excl-img.jpg");
  //           img.style.display = "inline";
  //           this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
  //           this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
  //             resp["data"].ogFileUrl
  //           );
  //           pdf.setAttribute("url", resp["data"].ogFileUrl);
  //           pdf.style.display = "block";
  //           this.pdfDisplay = this.pdfDisplay === "none" ? "block" : "inline";
  //           document.getElementById("my-loader").classList.add("hide");
  //         }

  //         if (extension === ".mp4") {
  //           this.filesUrl = resp["data"].thumbnail;
  //           this.initializeSwiper();
  //           this.thumbnailSpinner = false;
  //           if (video) {
  //             video.style.display = "inline";
  //             video.setAttribute("controls", "true");
  //             video.setAttribute("src", ogFileUrl);
  //             let vidStyle = document.getElementById("vidStyle");
  //             vidStyle.style.display = "block";
  //             let myLoadeer = document.getElementById("my-loader");
  //             if (myLoadeer) {
  //               myLoadeer.style.display = "none";
  //             }
  //           }
  //         } else if (
  //           extension === ".jpeg" ||
  //           extension === ".png" ||
  //           extension === ".jpg"
  //         ) {
  //           image.setAttribute("src", resp["data"].ogFileUrl);
  //           let myLoadeer = document.getElementById("my-loader");
  //           if (myLoadeer) {
  //             myLoadeer.style.display = "none";
  //           }
  //           let imgStyle = document.getElementById("imgStyle");
  //           imgStyle.style.display = "block";
  //           image.style.display = "inline";
  //           let imagestyle = document.getElementById("imageStyle");
  //           document.getElementById("my-loader").classList.add("hide");
  //         } else if (
  //           extension === ".pdf" ||
  //           extension === ".js" ||
  //           extension === ".html" ||
  //           extension === ".git" ||
  //           extension === ".angular"
  //         ) {
  //           const pdf = document.getElementById("pdf") as HTMLObjectElement;
  //           let pdfFileUrl = document.getElementById("upload-file");
  //           pdfFileUrl.innerHTML = "file uploaded successfully";
  //           let filename = document.getElementById("upload-name");
  //           filename.innerHTML = files[0].name;
  //           pdfFileUrl.innerHTML = "file uploaded successfully";

  //           let img = document.getElementById("prev-img");
  //           if (extension == ".pdf") {
  //             img.setAttribute("src", "../../../assets/pdf.png");
  //           }
  //           if (extension == ".js") {
  //             img.setAttribute("src", "../../../assets/js.png");
  //           }
  //           if (extension == ".html") {
  //             img.setAttribute("src", "../../../assets/html.png");
  //           }
  //           if (extension == ".git") {
  //             img.setAttribute("src", "../../../assets/git.png");
  //           }
  //           if (extension == ".angular") {
  //             img.setAttribute("src", "../../../assets/angular.png");
  //           }
  //           if (extension === ".xlsx") {
  //             img.setAttribute("src", "../../../assets/excel.png");
  //           }
  //           this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
  //         } else if (extension === ".pptx") {
  //           const excelElement = document.getElementById(
  //             "excel"
  //           ) as HTMLInputElement;
  //           let pdfFileUrl = document.getElementById("upload-file");
  //           let filename = document.getElementById("upload-name");
  //           filename.innerHTML = files[0].name;
  //           pdfFileUrl.innerHTML = "file uploaded successfully";
  //           let img = document.getElementById("prev-img");
  //           img.setAttribute("src", "../../../assets/ppt-file-img.png");
  //           pdfFileUrl.innerHTML = "file uploaded successfully";
  //           this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
  //           this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
  //             resp["data"].ogFileUrl
  //           );
  //         }

  //         uploader.remove();
  //         document.getElementById("my-loader").classList.add("hide");
  //       }
  //     });
  //   }
  // }
  getFileExtension(filename: string): string {
    return filename.slice(filename.lastIndexOf(".")).toLowerCase();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  deleteMedia(idx: string) {
    let id = document.getElementById("ImageId").textContent;
    if (id.length == 0) {
      return alertModalWithoutConfirm(
        "warning",
        "Please select file from the list"
      );
    }
    alertModalDelete("You won't be able to revert this!").then(
      (isConfirmed) => {
        if (isConfirmed) {
          this.mediaService.deleteOnyById(idx).subscribe(
            (res) => {
              console.log(res);
              setTimeout(() => {
                // this.router.navigate(["/upload"]);
                window.location.reload();
              }, 2000);
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            }
          );
        } else {
          alertModalDelete("Deletion cancelled");
        }
      }
    );
  }

  breakpoint(e: any) {
    console.log("breakpoint");
  }

  isLastSlide: boolean = false;
  afterChange(e: any) {}

  beforeChange(e: any) {
    console.log("beforeChange");
  }

  downloadImagesAll: any;

  async downloadFileAll(): Promise<File[]> {
    const downloadPromises = this.ResponseData["data"]["files_url"].map(
      async (fileUrl) => {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const filename = fileUrl.split("/").pop();
        return new File([blob], filename, { type: blob.type });
      }
    );
    return Promise.all(downloadPromises);
  }

  async downloadZip() {
    const filesArray = await this.downloadFileAll();
    const zip = new JSZip();
    const imageFolder = zip.folder("images");
    filesArray.forEach((file) => {
      imageFolder.file(file.name, file);
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "images.zip");
    });
  }

  selectedImageUrl: string = ""; // Store selected image URL
  selectedImageIndex: number = -1;

  onClickImage(imageUrl: string, index: number) {
    this.selectedImageUrl = imageUrl;
    this.selectedImageIndex = index;

    if (this.slickModal) {
      this.slickModal.slickGoTo(index);
    }
  }

  clientNameControl = new FormControl();
  recentClientNames = ["Client A", "Client B", "Client C", "Client D"];
  filteredSuggestions: string[] = [];
  showSuggestions = false;

  toggleSuggestions(): void {
    this.showSuggestions = !this.showSuggestions;
  }

  onInput(inputValue: string): void {
    this.filteredSuggestions = this.recentClientNames.filter((name) =>
      name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }
  selectSuggestion(suggestion: string): void {
    this.clientNameControl.setValue(suggestion);
    this.showSuggestions = false;
  }
  textInput: string = "";
  selectedImage: File | null = null;

  onSubmit() {}

  onImageSelect(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.selectedImage = files[0];
    }
  }
  createCollection() {
    var collectionTag = document.querySelector(
      ".collection-input"
    ) as HTMLInputElement;
    this.collectionTags = collectionTag.value;
    this.newCollection = true;
    console.log(collectionTag.value, "00000000000000");
    if (this.collectionTags && this.collectionType) {
      this.playList.push({
        name: this.collectionTags,
        type: this.collectionType,
      });
      collectionTag.value = "";
    }

    console.log(this.playList);
  }

  addCollectionType(string: any) {
    this.collectionType = string;
    console.log(this.collectionType);
  }
  AllPlayList: any;
  PlayListNames: any = [];
  //GET ALL PLAYLIST
  // getAllPlaylist() {
  //   this.mediaService.playLists().subscribe(
  //     (res) => {
  //       this.AllPlayList = res["data"];
  //       this.AllPlayList.map((list) => {
  //         list.play_list.forEach((list) => {
  //           const name = list.name;
  //           const type = list.type;
  //           this.PlayListNames.push({
  //             name: name,
  //             type: type,
  //           });
  //         });
  //       });
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  selectedTagId: any;
  selectedPlayList(index: any) {
    console.log(index, "selected");
    this.collectionTags = index.title;
    this.selectedTagId = index._id;
    this.newCollection = false;
    this.playList[index];
    console.log(this.playUsersList[index], "SELECT ");
    this.collectionType = this.playUsersList[index].type;
    this.collectionTags = this.playUsersList[index].name;
    this.selectedCollection = this.collectionTags;
    console.log(
      { collectionTags: this.collectionTags },
      "kkkkkkkkkkkkkkkkkkkkkk"
    );
    // this.createCollection();
  }

  removeSelected(title: string): void {
    this.collectionTags = "";
    this.selectedTagId = "";
  }

  // removeSelected(nameToRemove: any) {
  //   const indexToRemove = this.playList.findIndex(
  //     (item) => item.name === nameToRemove
  //   );
  //   console.log(nameToRemove, "remove playlist", this.playList);
  //   if (indexToRemove !== -1) {
  //     this.playList.splice(indexToRemove, 1);
  //     this.collectionTags = "";
  //     this.selectedCollection = "Add To Collection";
  //     this.existingCollections = "";
  //     this.selectedTagId = "";
  //     console.log(`Removed playlist with name: ${nameToRemove}`);
  //   } else {
  //     console.log(`Playlist with name ${nameToRemove} not found.`);
  //   }
  //   console.log(nameToRemove, "remove playlist", this.playList);
  // }

  playUsersList: any;

  getInvitation() {
    let id = localStorage.getItem("guestID");

    this.UserService.getOneUser(id).subscribe(
      (res) => {
        let data = res["data"];
        this.AllPlayList = data.user.play_list;
        this.playUsersList = data.user.play_list;
        // this.AllPlayList.map((item) => {
        //   item.forEach((data) => {
        //     console.log(data, "res =>");
        //   });
        // });
        const nameAndTypeArray = this.AllPlayList.map((item) => {
          return {
            name: item.play_list[0].name,
            type: item.play_list[0].type,
          };
        });

        const uniqueMap = {};
        const uniqueArray = [];

        for (const obj of nameAndTypeArray) {
          if (!uniqueMap[obj.name]) {
            uniqueMap[obj.name] = true;
            uniqueArray.push(obj);
          }
        }

        this.playUsersList = uniqueArray;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  bytesToMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  async onFileDrop(event: DragEvent) {
    console.log("Drag");
    event.preventDefault();
    var serv = this.mediaService;
    const input: any = document.getElementById("upload-input");
    const video: any = document.getElementById("preview-video");
    const image: any = document.getElementById("preview-image");
    const pdf: HTMLElement | null = document.getElementById("preview-pdf");
    if (pdf) {
      pdf.style.display = "none";
    }

    const uploader: any = document.getElementsByClassName("file-upload")[0];
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Updating file type in header
      const headerPara = document.querySelector(
        ".drag-file-type"
      ) as HTMLElement;
      // headerPara.innerText = file.type;
      console.log("Selected File", file.type);
      // headerPara.innerText =
      //   this.selectedTags === "image" ? "video/mp4" : "Image";
      const fileType = this.getFileType(file.type);
      this.selectedTags = fileType ? fileType : "Other";
      if (
        file.type == "video/mp4" ||
        file.type == "video/quicktime" ||
        file.type == "video/webm" ||
        file.type == "video/x-matroska"
      ) {
        this.thumbnailSpinner = true;
        this.showThumbnail = true;
      }
      this.dragAndDrop = file;
      try {
        if (["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
          const base64String = await this.fileToBase64(file);
          const image: any = document.getElementById("preview-image");

          image.src = `data:image/jpeg;base64,${base64String}`;
          this.archiveInProgress = false;
        }
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
      serv.createMedia(file).subscribe((event) => {
        console.log("Server Response:", event);
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(
            (100 / (event.total || 0)) * event.loaded
          );
          this.barWidth = progress + "%";
          let fileUpload = document.getElementById("file-uploadCamera");
          fileUpload.style.setProperty("display", "none", "important");
          document.getElementById("prog").innerHTML = this.barWidth;
          document.getElementById("progressBar").style.width = this.barWidth;
          this.uploadInProgress = true;

          document.getElementById("my-loader").classList.remove("hide");
        } else if (event.type === HttpEventType.Response) {
          this.barWidth = "0%";
          this.uploadInProgress = false;

          this.id = event.body["data"]._id;
          let imageid = (document.getElementById("ImageId").innerHTML =
            this.id);
          const resp = event.body;
          this.ResponseData = resp["data"];
          const extension = resp["data"].extension.toLowerCase();
          const ogFileUrl = resp["data"].lg;
          this.data = resp["data"].ogFileUrl;
          this.mediaId = this.ResponseData._id;
          if (extension === ".xlsx" || extension === ".xls") {
            var excel = document.getElementById("excel-preview");
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/excl-img.jpg");
            img.style.display = "inline";
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              resp["data"].ogFileUrl
            );
            this.archiveInProgress = false;
            pdf.setAttribute("url", resp["data"].ogFileUrl);
            pdf.style.display = "block";
            this.pdfDisplay = this.pdfDisplay === "none" ? "block" : "inline";
            document.getElementById("my-loader").classList.add("hide");
          }

          if (
            extension === ".mp4" ||
            extension === ".mov" ||
            extension === ".webm" ||
            extension === ".mkv"
          ) {
            this.filesUrl = resp["data"].thumbnail;
            this.initializeSwiper();
            this.thumbnailSpinner = true;
            if (video) {
              video.style.display = "inline";
              video.setAttribute("controls", "true");
              video.setAttribute("src", ogFileUrl);
              let vidStyle = document.getElementById("vidStyle");
              vidStyle.style.display = "block";
              let myLoadeer = document.getElementById("my-loader");
              if (myLoadeer) {
                myLoadeer.style.display = "none";
              }
            }
          } else if (
            extension === ".jpeg" ||
            extension === ".png" ||
            extension === ".jpg"
          ) {
          } else if (
            extension === ".pdf" ||
            extension === ".js" ||
            extension === ".html" ||
            extension === ".git" ||
            extension === ".angular"
          ) {
            const pdf = document.getElementById("pdf") as HTMLObjectElement;
            let pdfFileUrl = document.getElementById("upload-file");
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let filename = document.getElementById("upload-name");
            filename.innerHTML = files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";

            let img = document.getElementById("prev-img");
            if (extension == ".pdf") {
              img.setAttribute("src", "../../../assets/Archive_files/pdf.png");
            }
            if (extension == ".js") {
              img.setAttribute("src", "../../../assets/js.png");
            }
            if (extension == ".html") {
              img.setAttribute("src", "../../../assets/html.png");
            }
            if (extension == ".git") {
              img.setAttribute("src", "../../../assets/git.png");
            }
            if (extension == ".angular") {
              img.setAttribute("src", "../../../assets/angular.png");
            }
            if (extension === ".xlsx" || extension === ".xls") {
              img.setAttribute("src", "../../../assets/excel.png");
            }
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
          } else if (extension === ".pptx") {
            const excelElement = document.getElementById(
              "excel"
            ) as HTMLInputElement;
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/ppt-file-img.png");
            pdfFileUrl.innerHTML = "file uploaded successfully";
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              resp["data"].ogFileUrl
            );
            this.archiveInProgress = false;
          } else if (extension == ".doc" || extension == ".docx") {
            console.log("Hello");
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = files[0].name;
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/docx-img.png");
            this.archiveInProgress = false;
          }

          uploader.remove();
          document.getElementById("my-loader").classList.add("hide");
        }
      });
    }
  }
  getFileType(fileType: string): string | undefined {
    switch (fileType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return "Image";
      case "video/mp4":
      case "video/quicktime":
      case "video/webm":
      case "video/x-matroska":
        return "Video";
      case "application/pdf":
        return "pdf";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "Presentation";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
        return "Word/Doc";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "Excel";
      default:
        return undefined;
    }
  }
  async uploadMedia(ev: any) {
    let filesData = ev.target.files[0];
    this.socketUploadFile = filesData;
    if (["image/png", "image/jpeg", "image/jpg"].includes(filesData.type)) {
      try {
        const base64String = await this.fileToBase64(filesData);
        console.log(base64String, filesData);
        const image: any = document.getElementById("preview-image");
        image.src = `data:image/jpeg;base64,${base64String}`;
        this.archiveInProgress = false;
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
    console.log(this.socketUploadFile.size);
    console.log(filesData.type, "pppppppppppp");
    if (
      filesData.type == "video/mp4" ||
      filesData.type == "video/quicktime" ||
      filesData.type == "video/webm" ||
      filesData.type == "video/x-matroska"
    ) {
      this.thumbnailSpinner = true;
      this.showThumbnail = true;
    }
    ev = ev.target;

    const pdf: HTMLElement | null = document.getElementById("preview-pdf");
    if (document.getElementsByClassName("file-upload").length) {
      document.getElementsByClassName("file-upload")[0].remove();
    }
    if (document.getElementById("my-loader")) {
      document.getElementById("my-loader").classList.remove("hide");

      // document.getElementById("my-loader").style.display = "none";
    }
    const uploader: any = document.getElementsByClassName("file-upload")[0];
    const input: any = document.getElementById("upload-input");
    const video: any = document.getElementById("preview-video");
    const image: any = document.getElementById("preview-image");
    this.mediaService.createMedia(ev.files[0]).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(
            (100 / (event.total || 0)) * event.loaded
          );
          this.barWidth = progress + "%";
          document.getElementById("prog").innerHTML = this.barWidth;
          document.getElementById("progressBar").style.width = this.barWidth;
          this.uploadInProgress = true;
        } else if (event.type === HttpEventType.Response) {
          this.barWidth = "0%";
          this.uploadInProgress = false;
          this.id = event.body["data"]._id;
          document.getElementById("ImageId").innerHTML = this.id;
          const resp = event.body;
          console.log({ resp });
          this.ResponseData = resp["data"];
          console.log(this.ResponseData, "response data");
          this.mediaId = this.ResponseData._id;
          if (this.barWidth == "100%") {
            this.loaderUpload = true;
            document.getElementById("my-loader").classList.remove("hide");
            document.getElementById("my-loader").style.display = "none";
          }
          const extension = resp["data"].extension.toLowerCase();
          console.log("uploadProgress", extension, extension == ".doc");
          const ogFileUrl = resp["data"].lg;
          this.data = resp["data"].ogFileUrl;

          if (extension === ".xlsx" || extension === ".xls") {
            var excel = document.getElementById("excel-preview");
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = ev.files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/excl-img.jpg");
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              resp["data"].ogFileUrl
            );
            this.archiveInProgress = false;
            pdf.setAttribute("url", resp["data"].ogFileUrl);
            pdf.style.display = "block";
            this.pdfDisplay = this.pdfDisplay === "none" ? "block" : "inline";
            document.getElementById("my-loader").classList.add("hide");
          }

          if (
            extension === ".mp4" ||
            extension === ".mov" ||
            extension === ".webm" ||
            extension === ".mkv"
          ) {
            this.filesUrl = resp["data"].thumbnail;
            this.initializeSwiper();

            if (video && ogFileUrl) {
              video.style.display = "inline";
              video.setAttribute("controls", "true");
              video.setAttribute("src", ogFileUrl);
              let myLoadeer = document.getElementById("my-loader");
              if (myLoadeer) {
                myLoadeer.style.display = "none";
              }
              let vidStyle = document.getElementById("vidStyle");
              if (vidStyle) {
                vidStyle.style.display = "block";
              }
            }
          } else if (
            extension === ".jpeg" ||
            extension === ".png" ||
            extension === ".jpg"
          ) {
            document.getElementById("my-loader")?.classList?.add("hide");
          } else if (
            extension === ".pdf" ||
            extension === ".js" ||
            extension === ".html" ||
            extension === ".git" ||
            extension === ".angular"
          ) {
            const pdf = document.getElementById("pdf") as HTMLObjectElement;
            let pdfFileUrl = document.getElementById("upload-file");
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let filename = document.getElementById("upload-name");
            filename.innerHTML = ev.files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";
            this.archiveInProgress = false;
            let img = document.getElementById("prev-img");
            if (extension == ".pdf") {
              img.setAttribute("src", "../../../assets/Archive_files/pdf.png");
            }
            if (extension == ".js") {
              img.setAttribute("src", "../../../assets/js.png");
            }
            if (extension == ".html") {
              img.setAttribute("src", "../../../assets/html.png");
            }
            if (extension == ".git") {
              img.setAttribute("src", "../../../assets/git.png");
            }
            if (extension == ".angular") {
              img.setAttribute("src", "../../../assets/angular.png");
            }
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
          } else if (extension === ".pptx") {
            const excelElement = document.getElementById(
              "excel"
            ) as HTMLInputElement;
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = ev.files[0].name;
            pdfFileUrl.innerHTML = "file uploaded successfully";
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/ppt-file-img.png");
            pdfFileUrl.innerHTML = "file uploaded successfully";
            this.pdfDis = this.pdfDis == "inline" ? "inline" : "none";
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              resp["data"].ogFileUrl
            );
            this.archiveInProgress = false;
          } else if (extension == ".doc" || extension == ".docx") {
            console.log("Hello");
            let pdfFileUrl = document.getElementById("upload-file");
            let filename = document.getElementById("upload-name");
            filename.innerHTML = ev.files[0].name;
            let img = document.getElementById("prev-img");
            img.setAttribute("src", "../../../assets/docx-img.png");
            this.archiveInProgress = false;
          }
          // uploader.remove();
          if (uploader) {
            uploader.remove();
          }
          // document.getElementById("my-loader").classList.add("hide");
          let myLoader = document.getElementById("my-loader");
          if (myLoader) {
            myLoader.classList.add("hide");
          }
        }
      },

      (error) => {
        console.log(error);
        if (error.error.data.error == true) {
          document.getElementById("my-loader").classList.remove("hide");
          this.loaderUpload = true;
          this.barWidth = "0%";
          this.uploadInProgress = false;

          this.messageError = error.error.data.msg;
          // document.getElementById("my-loader").style.display = "none";
          console.log(error.error.data);
        }
      }
    );
  }
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        // Split the base64 string to remove the data URI prefix
        const base64WithoutPrefix = base64String.split(",")[1];
        resolve(base64WithoutPrefix);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  existingCollections: any;
  addToExistingCollection() {
    this.mediaService.getCollectionGroup().subscribe((res) => {
      this.existingCollections = res["data"];
    });
  }
}
