
<div
class="d-flex justify-content-center"
style="
  font-size: 40px;
  width: 100%;
  margin-top: 70px;
  flex-direction: column;
  position: relative;
"
>
<img
  src="../../../assets/no-data.jpg"
  alt=""
  width="500px"
  style="position: absolute; left: 30%; top: 15%"
/>
<p style="position: absolute; left: 39%; font-size: 30px;font-weight: 600;">No Request Available</p>
</div>